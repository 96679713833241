
export default {
    getOfferReview(giataId) {
        // add header Authorization api key
        return fetch(`https://connector-staging.traffics-switch.de/v3/rest/hotels/${giataId}/reviews?source=holidaycheck`, {
            headers: {
                'Authorization': 'UG9jaXRuaWNlLmNvbnRlc3Q6Sm1tQ3RPM3F=',
            }
        })
            .then((response) => response.json())
            .then((json) => {
                return json.hotelReviewList;
            });
    },
    translete(payload = null, lang = "sl") {
        const GoogleApiKey = import.meta.env.VITE_GOOGLE_API_KEY;
        const apiUrl = `https://translation.googleapis.com/language/translate/v2?key=${GoogleApiKey}`;
        const requestData = {
            q: payload,
            target: lang,
        };

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
        };

        return fetch(apiUrl, requestOptions)
            .then((response) => response.json())
            .then((json) => {
                return json.data.translations[0].translatedText;
            })
            .catch((error) => {
                console.error('Error translating text:', error);
            });
    },
};